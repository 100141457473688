import { useState } from 'react'
import { Button, IconButton, NotificationsSettingsDrawer } from 'components'
import { useAuth, useNotifications } from 'hooks'
import ReactMarkdown from 'react-markdown'
import { dateFormat } from 'utils'
import {
  Box,
  Center,
  Container,
  Flex,
  Group,
  Image,
  Paper,
  SegmentedControl,
  Skeleton,
  Space,
  Stack,
  Text,
} from '@mantine/core'
import { Setting2 } from 'iconsax-react'
import { topics } from 'constants/topics'
import { showNotification } from '@mantine/notifications'
import { useDisclosure } from '@mantine/hooks'

export default function Notifications() {
  const [opened, { open, close }] = useDisclosure(false)
  const [tab, setTab] = useState('notSeen')
  const {
    notifications,
    loading,
    hideNotificationMessage,
    notificationMessage,
    pushNotificationSupported,
    markNotificationAsSeen,
  } = useNotifications()

  const handleNotificationClick = (docId) => {
    markNotificationAsSeen(docId)
  }

  return (
    <>
      <Box component="section" aria-label="Header" mb={24}>
        <Flex justify="space-between" align="center">
          <Box sx={{ flex: 1 }}>
            <Text inline fz="xl">
              Centro de notificaciones
            </Text>
          </Box>

          <IconButton
            disabled={!notificationMessage}
            onClick={open}
            radius="xl"
            size="xl"
            variant="light"
          >
            <Setting2 />
          </IconButton>
        </Flex>
      </Box>
      {pushNotificationSupported && !notificationMessage ? (
        <ActivateNotifications onClick={hideNotificationMessage} />
      ) : (
        <>
          <SegmentedControl
            value={tab}
            onChange={setTab}
            fullWidth
            size="md"
            radius="md"
            mb="lg"
            data={[
              { label: 'No leídas', value: 'notSeen' },
              { label: 'Leídas', value: 'seen' },
            ]}
            styles={{
              active: { boxShadow: 'none' },
              label: { fontSize: '0.9rem' },
            }}
          />
          <Container px={0} pb="lg">
            <Stack spacing="sm">
              {loading ? (
                new Array(4)
                  .fill('')
                  .map((it, idx) => (
                    <Skeleton key={idx} height={180} radius="lg" />
                  ))
              ) : (
                <>
                  {tab === 'notSeen' &&
                    notifications.notSeen.map((notification) => {
                      return (
                        <NotificationItem
                          key={notification.created_at}
                          markAsSeen={handleNotificationClick}
                          {...notification}
                        />
                      )
                    })}
                  {tab === 'seen' &&
                    notifications.seen.map((notification) => {
                      return (
                        <NotificationItem
                          key={notification.created_at}
                          {...notification}
                        />
                      )
                    })}
                </>
              )}
            </Stack>
          </Container>
        </>
      )}
      <NotificationsSettingsDrawer opened={opened} close={close} />
    </>
  )
}

const ActivateNotifications = ({ onClick }) => {
  const { checkForNotificationsConsent, initNotifications } = useNotifications()

  const [loading, setLoading] = useState(false)

  const { user } = useAuth()
  const allTopics = Object.values(topics)
  const userInterests = user.interests ?? allTopics

  const askForNotificationPermission = async () => {
    setLoading(true)
    const { error, consent } = await checkForNotificationsConsent()
    if (consent === 'granted') {
      return initNotifications(userInterests).then(() => {
        showNotification({
          message: 'Notificaciones configuradas correctamente.',
          color: 'green',
          style: { marginBottom: 64 },
        })
        onClick()
      })
    }

    onClick()
    showNotification({
      message: error.message,
      color: 'red',
      autoClose: false,
      style: { marginBottom: 64 },
    })
    setLoading(false)
  }

  return (
    <Center h="calc(100vh - 200px)">
      <Paper radius="lg" p="md" withBorder>
        <Box mx="auto" h={132} w={168} my={16}>
          <Image src="/images/icons/notifications.png" alt="Documentos" />
        </Box>
        <Text mb="sm" c="dimmed">
          Para recibir notificaciones, debes activar primero el uso de las
          mismas. Más adelante puedes cambiar la configuración desde los
          ajustes.
        </Text>
        <Text fw={500}>Recuerda que:</Text>
        <Text>
          No leer las notificaciones importantes puede conllevar a la retirada
          del certificado.
        </Text>
        <Space h="md" />
        <Button
          loading={loading}
          onClick={askForNotificationPermission}
          fullWidth
          text="Activar notificaciones"
        />
      </Paper>
    </Center>
  )
}

const NotificationItem = ({
  body,
  created_at,
  link,
  title,
  topic,
  docId,
  markAsSeen,
}) => {
  const handleClick = () => {
    if (link) {
      window.open(link, '_blank')
    }
    markAsSeen(docId)
  }

  return (
    <Paper radius="lg" p="md" withBorder>
      <Text fz="sm" fw={500}>
        {title}
      </Text>
      <Group spacing="xs" mb={8}>
        <Text
          fz="xs"
          px={8}
          bg="gray.2"
          c="gray.8"
          sx={{ borderRadius: 4, maxWidth: 'fit-content' }}
        >
          {topic.toUpperCase()}
        </Text>
        <Text c="dimmed" fz="sm">
          •
        </Text>
        <Text c="dimmed" fz="sm">
          {dateFormat(created_at.toDate(), 'dd MMM yyyy')}
        </Text>
      </Group>
      <ReactMarkdown
        components={{
          p: ({ ...props }) => <Text my={0} {...props} />,
          strong: ({ ...props }) => (
            <Text component="strong" fw={500} c="brand" {...props} />
          ),
        }}
      >
        {body}
      </ReactMarkdown>
      {(markAsSeen || link) && (
        <Group position="right" mt={8}>
          <Button
            variant="light"
            text={link ? 'Ir al enlace' : 'Marcar como leída'}
            onClick={handleClick}
          />
        </Group>
      )}
    </Paper>
  )
}
