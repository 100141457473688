import { createContext, useEffect, useReducer } from 'react'
import { useAuth, useFirebase } from 'hooks'
import { useLocalStorage } from 'react-use'
import {
  askUserPermission,
  isPushNotificationSupported,
} from 'serviceWorkerRegistration'
import { deleteToken, getToken } from 'firebase/messaging'
import { messaging } from 'configs/firebaseConfig'
import { serverTimestamp } from 'firebase/firestore'
import { DEBUG } from 'utils'

const NotificationsContext = createContext([{}, () => {}])

const NotificationsProvider = ({ children }) => {
  const { user } = useAuth()
  const [notificationMessage, setNotificationMessage] = useLocalStorage(
    'notifications',
    false
  )
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      loading: true,
      notifications: {
        seen: [],
        notSeen: [],
      },
      unread: false,
      count: null,
      error: null,
      notificationMessage: notificationMessage,
      subscribing: false,
    }
  )
  const { markAsRead, updateUser, getUserNotifications } = useFirebase()
  const pushNotificationSupported = isPushNotificationSupported()

  useEffect(() => {
    if (user) {
      getNotifications()
      onTokenRefresh()
      // onMessage(messaging, (event) => {
      //   console.log({ event })
      // })
    }
  }, [user])

  const getNotifications = async () => {
    const notifications = await getUserNotifications({
      interests: user.interests,
      uid: user.uid,
    })

    const seen = notifications.filter((notification) => notification.seen)
    const notSeen = notifications.filter((notification) => !notification.seen)

    setState({ notifications: { seen, notSeen }, loading: false })
  }

  const markNotificationAsSeen = async (docId) => {
    const notification = state.notifications.notSeen.find(
      (item) => item.docId === docId
    )
    const updatedNotSeen = state.notifications.notSeen.filter(
      (notification) => notification.docId !== docId
    )
    const updatedSeen = [].concat(state.notifications.seen)
    updatedSeen.unshift(notification)

    setState({
      notifications: {
        seen: updatedSeen,
        notSeen: updatedNotSeen,
      },
    })
    markAsRead({ docId, uid: user.uid })
  }

  const checkForNotificationsConsent = async () => {
    let error = null
    let consent = null

    if (pushNotificationSupported) {
      consent = Notification.permission

      if (consent !== 'granted') {
        consent = await askUserPermission()
      }

      switch (consent) {
        case 'denied':
          error = {
            name: 'Consent denied',
            message:
              'Has denegado el permiso para recibir notificaciones. Debes activarlas manualmente en la configuración de tu navegador. Busca el icono del candado en la barra de direcciones, pulsa en él y selecciona "Permisos para este sitio". Desde allí, busca la sección de notificaciones y actívala.',
            code: 1,
          }
          break

        default:
          break
      }
    } else {
      // Notifications not supported
      consent = 'not_available'
      error = {
        name: 'Notification not supported',
        message: 'Las notificaciones no están disponibles en este dispositivo.',
        code: 1,
      }
    }

    return { error, consent }
  }

  const initNotifications = async (topics) => {
    try {
      setState({ subscribing: true })
      let USER_FCM_TOKEN = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
      })

      if (!USER_FCM_TOKEN) {
        return
      }

      // Saving the token on db
      await updateUserToken(USER_FCM_TOKEN.toString())

      if (USER_FCM_TOKEN) {
        for (const topic of topics) {
          await subscribeToTopic(topic)
        }
        setState({ subscribing: false })
        return USER_FCM_TOKEN
      } else {
        if (DEBUG) {
          console.log('An error occurred while retrieving token.')
        }
        return null
      }
    } catch (error) {
      console.error('Error initializing notifications:', error)
    }
  }

  // Subscribe the device to a topic
  const subscribeToTopic = async (topic) => {
    const currentToken = await getToken(messaging)
    const topicURL = `https://iid.googleapis.com/iid/v1/${currentToken}/details=true`
    await fetch(topicURL, {
      method: 'POST',
      headers: new Headers({
        Authorization: `key=${process.env.REACT_APP_SERVER_KEY}`,
        'Content-Type': 'application/json',
      }),
    })
      .then((resp) => {
        console.log({ resp: resp.statusText })
        if (resp.status < 200 || resp.status >= 400) {
          throw (
            `Error ${resp.status} subscribing to topic: ${topic}` +
            ' - ' +
            resp.statusText
          )
        }
      })
      .catch((error) => {
        console.error(`Error ${error} subscribing device to topic: ${topic}`)
      })
  }

  // Unsubscribe the device from a topic
  const unsubscribeFromTopic = async (topic) => {
    const currentToken = await getToken(messaging)
    const topicURL = `https://iid.googleapis.com/iid/v1/${currentToken}/rel/topics/${topic}`
    await fetch(topicURL, {
      method: 'DELETE',
      headers: new Headers({
        Authorization: `key=${process.env.REACT_APP_SERVER_KEY}`,
      }),
    })
      .then((resp) => {
        if (resp.status < 200 || resp.status >= 400) {
          throw (
            'Error unSubscribing to topic: ' + resp.status + ' - ' + resp.text()
          )
        }
      })
      .catch((error) => {
        console.error('Error unsubscribing device to topic:', error)
      })
  }

  const onTokenRefresh = async () => {
    // Check that the worker is registered
    const isReady = await navigator.serviceWorker.ready
    if (isReady) {
      const consent = Notification.permission
      if (consent === 'granted') {
        try {
          const vapidKey = process.env.REACT_APP_VAPID_KEY
          const token = await getToken(messaging, {
            vapidKey,
          })
          console.log({ token })
          if (token) {
            updateUserToken(token)
          }
        } catch (error) {
          console.error('Error refreshing token:', error)
        }
      }
    }
  }

  const updateUserToken = async (token) => {
    if (user && user.notificationToken !== token) {
      await updateUser({
        data: {
          notificationToken: token,
          tokenTimestamp: serverTimestamp(),
        },
        docId: user.userDocId,
      })
    }
  }

  const getSubscriptionInfo = async () => {
    const currentToken = await getToken(messaging)
    await updateUserToken(currentToken.toString())
    const fetchUrl = `https://iid.googleapis.com/iid/info/${currentToken}?details=true`
    const appInstancesInfo = await fetch(fetchUrl, {
      method: 'GET',
      headers: new Headers({
        Authorization: `key=${process.env.REACT_APP_SERVER_KEY}`,
        'Content-Type': 'application/json',
      }),
    })
      .then((resp) => {
        if (resp.status < 200 || resp.status >= 400) {
          throw 'Error getting info: ' + resp.status + ' - ' + resp.statusText
        }
        return resp.json()
      })
      .catch((error) => {
        console.error(error)
      })
    return appInstancesInfo
  }

  const removeSubscription = async () => {
    deleteToken(messaging)
      .then((resp) => {
        // console.log({ resp })
        if (resp.status < 200 || resp.status >= 400) {
          throw (
            'Error deleting subscription: ' +
            resp.status +
            ' - ' +
            resp.statusText
          )
        }
        return resp
      })
      .catch((error) => {
        console.error(error)
        throw 'Error deleting subscription: ' + error
      })
  }

  const hideNotificationMessage = () => {
    setState({ notificationMessage: true })
    setNotificationMessage(true)
  }

  return (
    <NotificationsContext.Provider
      value={{
        ...state,
        hideNotificationMessage,
        getNotifications,
        checkForNotificationsConsent,
        markNotificationAsSeen,
        initNotifications,
        subscribeToTopic,
        unsubscribeFromTopic,
        getSubscriptionInfo,
        removeSubscription,
        pushNotificationSupported,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}

export { NotificationsContext, NotificationsProvider }
