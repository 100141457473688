import React, { useEffect, useMemo, useReducer } from 'react'
import * as serviceWorker from '../serviceWorkerRegistration'

const ServiceWorkerContext = React.createContext()

const ServiceWorkerProvider = ({ children }) => {
  const [state, setState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      waitingServiceWorker: null,
      isUpdateAvailable: false,
      supportsPWA: false,
      promptInstall: null,
      isInstalled: null,
    }
  )

  useEffect(() => {
    serviceWorker.register({
      onUpdate: (registration) => {
        setState({
          waitingServiceWorker: registration.waiting,
          isUpdateAvailable: true,
        })
      },
    })

    const handler = (e) => {
      e.preventDefault()
      // console.log('Not installed yet')
      setState({
        supportsPWA: true,
        promptInstall: e,
        isInstalled: false,
      })
    }
    const alreadyInstalled = () => {
      // console.log('Is installed')
      setState({ isInstalled: true })
    }
    window.addEventListener('beforeinstallprompt', handler)
    window.addEventListener('appinstalled', alreadyInstalled)

    return () => {
      window.removeEventListener('beforeinstallprompt', handler)
      window.removeEventListener('appinstalled', alreadyInstalled)
    }
  }, [])

  useEffect(() => {
    // We setup an event listener to automatically reload the page
    // after the Service Worker has been updated, this will trigger
    // on all the open tabs of our application, so that we don't leave
    // any tab in an inconsistent state
    if (state.waitingServiceWorker) {
      state.waitingServiceWorker.addEventListener('statechange', (event) => {
        if (event.target.state === 'activated') {
          window.location.reload()
        }
      })
    }
  }, [state.waitingServiceWorker])

  const value = useMemo(
    () => ({
      isUpdateAvailable: state.isUpdateAvailable,
      supportsPWA: state.supportsPWA,
      isInstalled: state.isInstalled,
      updateAssets: () => {
        if (state.waitingServiceWorker) {
          // We send the SKIP_WAITING message to tell the Service Worker
          // to update its cache and flush the old one
          state.waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
        }
        if ('serviceWorker' in navigator) {
          caches.keys().then(function (cacheNames) {
            cacheNames.forEach(function (cacheName) {
              caches.delete(cacheName)
            })
          })
        }
        window.location.reload()
      },
      handleInstall: () => {
        if (state.promptInstall) {
          return state.promptInstall.prompt()
        }
        return Promise.reject(
          new Error(
            'Tried installing before browser sent "beforeinstallprompt" event'
          )
        )
      },
    }),
    [state]
  )

  return (
    <ServiceWorkerContext.Provider value={value}>
      {children}
    </ServiceWorkerContext.Provider>
  )
}

export { ServiceWorkerContext, ServiceWorkerProvider }
